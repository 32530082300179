import moment from "moment";
import { Vue } from "vue-class-component";

export default class SAMixin extends Vue {

  private paycodeList = [
    { name: "Regular" },
    { name: "Meet Con" },
    { name: "Training" },
    { name: "Stat Holiday" },
    { name: "Stat Holiday Not Working" },
    { name: "Birthday" },
    { name: "Stat Work" },
    { name: "Personal" },
    { name: "Vacation" },
    { name: "Bereavmt" },
    { name: "Sick Hrs" },
    { name: "Overtime" },
    { name: "None" },
  ];

  sumTwoTimes(current, WeekHrs) {
    const Hrs = current[0] + WeekHrs[0];
    const min = current[1] + WeekHrs[1];
    const quotient = Math.floor(min / 60);
    return Hrs + quotient;
  }

  checkBreakTimings(timeIn, timeOut, paycode) {
    let breakTimes = "";

    const current = this.currentTimeDiff(timeOut, timeIn);

    if (current[0] >= 6 && current[0] < 10) {
      breakTimes = "30 min";
    } else if (
      current[0] >= 11 &&
      paycode != "Regular" &&
      paycode != "Overtime" &&
      paycode != "Stat Holiday"
    ) {
      breakTimes = "60 min";
    } else if (
      current[0] >= 11 &&
      (paycode == "Regular" ||
        paycode == "Overtime" ||
        paycode == "Stat Holiday")
    ) {
      breakTimes = "30 min";
    }

    return breakTimes;
  }

  currentTimeDiffDecimal(timeOut, timeIn) {
    const ms = moment(timeOut, "HH:mm").diff(moment(timeIn, "HH:mm"));
    const d = moment.duration(ms).asHours();
    return d;
  }

  currentTimeDiff(timeOut, timeIn) {
    const ms = moment(timeOut, "HH:mm").diff(moment(timeIn, "HH:mm"));
    const d = moment.duration(ms);
    const quotient = Math.floor(d.minutes() / 60);
    const remainder = d.minutes() % 60;
    return [d.hours() + quotient, remainder];
  }

  checkTimeValid(timeIn, timeOut) {
    const beforeTime = moment(timeIn, "HH:mm");
    const afterTime = moment(timeOut, "HH:mm");

    let status = false;

    if (beforeTime.isBefore(afterTime)) {
      status = true;
    } else {
      status = false;
    }

    return status;
  }

  checkMomentTimeBetween(currentTime, currentTimeOut, timeIn, timeOut) {
    let res = "";

    const time = moment(currentTime, "HH:mm");
    const ctimeOut = moment(currentTimeOut, "HH:mm");
    const beforeTime = moment(timeIn, "HH:mm");
    const afterTime = moment(timeOut, "HH:mm");

    if (
      time.isBetween(beforeTime, afterTime) ||
      ctimeOut.isBetween(beforeTime, afterTime) ||
      time.isSame(beforeTime) ||
      ctimeOut.isSame(afterTime) ||
      (time.isBefore(beforeTime) && ctimeOut.isAfter(afterTime))
    ) {
      res = "occupied";
    } else {
      res = "available";
    }

    return res;
  }

  getTimeDiffmoment(start, end, breakTimings) {
    if (breakTimings != "") {
      if (breakTimings == "30 min") {
        end = moment(end, "HH:mm")
          .subtract(30, "minutes")
          .format("HH:mm");
      } else if (breakTimings == "60 min") {
        end = moment(end, "HH:mm")
          .subtract(60, "minutes")
          .format("HH:mm");
      }
    }

    const ms = moment(end, "HH:mm").diff(moment(start, "HH:mm"));
    const d = moment.duration(ms);
    return d;
  }

  newTimeOut(existing, constHrs, timeIn) {
    const t = constHrs - existing[0];

    const min = existing[1];

    let newTime = moment(timeIn, "HH:mm")
      .add(String(t), "hours")
      .format("HH:mm");

    if (t >= 6 && t < 10) {
      //ADDING BREAK TIME
      newTime = moment(newTime, "HH:mm")
        .add(String(30), "minutes")
        .format("HH:mm");
    }

    newTime = moment(newTime, "HH:mm")
      .subtract(String(min), "minutes")
      .format("HH:mm");

    return newTime;
  }
  
  newTimeHAOut(existing, constHrs, timeIn) {
    const t = constHrs - existing;

    let newTime = moment(timeIn, "HH:mm")
      .add(String(t), "hours")
      .format("HH:mm");

    return newTime;
  }

  getWeekNo(date) {
    return String(moment(date, "YYYY-MM-DD").week());
  }

  getTimeDiff(end, start, breakTimings) {
    if (breakTimings == "30 min") {
      end = moment(end, "HH:mm")
        .subtract(30, "minutes")
        .format("HH:mm");
    } else if (breakTimings == "60 min") {
      end = moment(end, "HH:mm")
        .subtract(60, "minutes")
        .format("HH:mm");
    }

    const ms = moment(end, "HH:mm").diff(moment(start, "HH:mm"));
    const d = moment.duration(ms);

    const hours = d.hours();
    const minutes = d.minutes();
    return hours + " Hrs & " + minutes + " min";
  }

  filterCurrentSchHrs(timeIn, timeOut, breakTimings) {
    let totalHrs = 0;

    if (breakTimings == "30 min") {
      timeOut = moment(timeOut, "HH:mm")
        .subtract(30, "minutes")
        .format("HH:mm");
    } else if (breakTimings == "60 min") {
      timeOut = moment(timeOut, "HH:mm")
        .subtract(60, "minutes")
        .format("HH:mm");
    }

    const ms = moment(timeOut, "HH:mm").diff(moment(timeIn, "HH:mm"));

    const d = moment.duration(ms).asHours();

    totalHrs = totalHrs + d;

    return Math.round(totalHrs * 100) / 100;
  }


  showAdjustedTime(timeIn, hrs) {
    const newTime = moment(timeIn, "HH:mm")
      .add(hrs, "hours")
      .format("HH:mm");
    return this.formatTime(timeIn) + " - " + this.formatTime(newTime);
  }

  formatTime(value) {
    let d = "";
    if (value) {
      const time = moment.duration(value);
      d =  moment(String(time), "HH:mm").format("hh:mm A");
    }
    return d;
  }

  postDate(value) {
    if (value) {
      return moment(value).format("YYYY-MM-DD");
    }
  }
}
