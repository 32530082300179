import moment from "moment";
import { Vue } from "vue-class-component";
import { camelCase } from "lodash";

// You can declare mixins as the same style as components.
export default class UtilityOptions extends Vue {

  formatDate(value) {
    let d = "";
    if (value) {
      d =  moment(value).format("DD-MM-YYYY");
    }
    return d;
  }

  getDayName(date)
  {
    let d = "";
    
    if(date != '')
    {
      d = moment(date).format('ddd');
    }

    return d;
  }

  formatTime(value) {
    let d = "";
    if (value) {
      d =  moment(value, "HH:mm").format("hh:mm A");
    }
    return d;
  }

  formatAmount(value) {
    value = Number(value);
    return value.toFixed(2);
  }

  camelizeKeys = (obj) => {
    if (Array.isArray(obj)) {
      return obj.map((v) => this.camelizeKeys(v));
    } else if (obj !== null && obj.constructor === Object) {
      return Object.keys(obj).reduce(
        (result, key) => ({
          ...result,
          [camelCase(key)]: this.camelizeKeys(obj[key]),
        }),
        {}
      );
    }
    return obj;
  };

  parseString(value) {
    return String(value) + "x";
  }

  getPaymentMethod(paymnetList) {
    let method = "";

    if (paymnetList.length == 0) {
      method = "Pay Later";
    } else if (paymnetList.length == 1) {
      method = paymnetList[0].paymentType;
    } else if (paymnetList.length > 1) {
      method = "Split";
    }

    return method;
  }

  shouldOpenDrawer(paymnetList) {
    let status = "No";

      paymnetList.forEach(e => {

        if(e.paymentType == 'Cash')
        {
          status = "Yes";
        }
        
      });

    return status;
  }
}